.hero-slider {
  $root: &;
  overflow: hidden;

  &__wrapper-outer {
    position: relative;
  }

  &__wrapper {
    position: relative;
  }

  &__picture-wrap {
    position: relative;
    display: block;
    z-index: 0;
    width: 100%;
    height: 60vw;
    overflow: hidden;
    min-height: 440px;
    max-height: 480px;

    @media (min-width: $screen-tablet-portrait) {
      height: 370px;
      max-height: 370px;
    }

    @media (min-width: $screen-tablet-landscape) {
      height: 480px;
      max-height: 480px;
    }

    @media (min-width: $screen-desktop) {
      height: 645px;
      max-height: 645px;
    }

    .hero-slider--hero & {
      display: flex;
      justify-content: flex-start;
      min-height: auto;
      max-height: 390px;
      height: auto;

      @media (min-width: $screen-tablet-portrait) {
        min-height: 280px;
        max-height: none;
        justify-content: center;
      }
    }
  }

  &__picture {
    margin: auto;
    z-index: 0;
    opacity: 0;
    transition: opacity 1400ms ease-out;

    .carousel__slide--active & {
      opacity: 1;
    }

    .is_fire-fox & { // stylelint-disable-line
      opacity: 1;
      transition: none;
    }

    &-placeholder {
      opacity: 1;
    }
  }

  &__picture-image {
    height: 100%;
    width: auto;
    position: absolute;
    z-index: 1;
    margin: auto;
    top: -6666px;
    left: -6666px;
    right: -6666px;
    bottom: -6666px;

    .hero-slider--hero & {
      height: auto;
      width: 100vw;
      position: relative;
      z-index: 1;
      margin: auto;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;

      @media (min-width: $screen-tablet-portrait) {
        width: auto;
        height: 100%;
      }
    }
  }

  &__box-wrap {
    @include container($maxWidthOuter);
    padding: 12px 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    height: 100%;

    @media (min-width: $screen-tablet-portrait) {
      justify-content: flex-end;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__box {
    position: relative;
    text-align: left;
    height: auto;
    z-index: 10;
    max-width: 480px;
    margin: 8px 40px;
    padding: 4px;
    background-clip: padding-box;
    border: solid 2px transparent;
    border-image-slice: 1; // stylelint-disable-line
    border-image-source: linear-gradient(90deg, rgb(241 232 215 / .9) 31.43%, rgb(241 232 215 / .7) 97.99%); // stylelint-disable-line

    @media (min-width: $screen-tablet-portrait) {
      margin: 0;
      padding: 9px;
      background-clip: padding-box;
      border-width: 6px;
      max-width: calc(50% - 45px);
      left: 45px;
      height: auto;
    }

    @media (min-width: $screen-desktop) {
      max-width: 480px;
      left: 80px;
      bottom: 75px;
    }

    .is_safari & {
      border: solid 2px $sandColor;
    }

    &-inner {
      padding: 10px 24px;
      width: 100%;
      height: auto;
      background: $sandColor;
      background: linear-gradient(90deg, rgb(241 232 215 / .9) 31.43%, rgb(241 232 215 / .7) 97.99%); // stylelint-disable-line  plugin/no-unsupported-browser-features

      @media (min-width: $screen-tablet-portrait) {
        padding: 20px 24px;
      }

      @media (min-width: $screen-desktop-sm) {
        padding: 30px;
        height: 100%;
      }
    }
  }

  &__text-wrap {
    @include container();
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
  }

  &__text {
    padding: 16px 0 0;
    margin: auto;
    text-align: left;
    position: relative;
    height: 100%;
    z-index: 10;

    @media (min-width: $screen-tablet-portrait) {
      position: absolute;
      width: 60%;
      height: auto;
      padding: 0;
      top: 180px;
    }

    @media (min-width: $screen-desktop-sm) {
      top: 250px;
    }

    &-inner {
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__overline {
    @include fontSize(16px);
    line-height: 1.4;
    color: $textBaseColor;
    font-weight: $fontWeightBold;
    margin: 0;
  }

  &__text-headline {
    font-size: 24px;
    text-align: left;
    padding: 0;
    text-transform: none;
    line-height: 1.4;
    color: $whiteColor;
    margin: 0 0 8px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 34px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 45px;
    }
  }

  &__text-subheadline {
    font-size: 13px;
    line-height: 1.4;
    color: $whiteColor;
    margin: 0;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 15px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 17px;
    }

    p {
      margin: 0;
      padding: 0;
    }

    a {
      color: $whiteColor;

      &:hover {
        color: $whiteColor;
        text-decoration-color: $whiteColor;
      }
    }
  }

  &__text-info {
    color: $whiteColor;

    .button {
      color: $whiteColor;

      &:hover {
        color: $whiteColor;
        text-decoration-color: $whiteColor;
      }
    }
  }

  &__text-container {
    padding: 0;
    position: relative;

    @media (min-width: $screen-tablet-portrait) {
      background: none;
      padding: 1px;
    }
  }

  &__headline {
    color: $textDarkestColor;
    text-align: center;
    font-size: 24px;
    font-family: $fontHeadlineFamily;
    font-style: normal;
    font-weight: $fontWeightBold;
    line-height: 31px;
    letter-spacing: .3px;
    margin: 0 0 5px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 34px;
      line-height: 34px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 45px;
      line-height: 45px;
      margin: 0 0 24px;
    }
  }

  &__subheadline {
    color: $textDarkestColor;
    text-align: center;
    font-size: 13px;
    font-family: $fontPrimaryFamily;
    font-style: normal;
    font-weight: $fontWeightRegular;
    line-height: 1.3;
    letter-spacing: -.4px;
    margin: 8px 0 0;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 15px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 17px;
      margin: 16px 0 0;
    }
  }

  &__link-button {
    min-width: 174px;
    padding: 0 16px;
    width: auto;
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0 24px;
      min-width: 220px;
      display: inline-block;
      margin-top: 20px;
    }

    @media (min-width: $screen-tablet-landscape) {
      padding: 0 32px;
      min-width: 230px;
    }

    &--product-mobile {
      display: inline-block;
      z-index: 1;

      @media (min-width: $screen-tablet-portrait) {
        display: none;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      justify-content: center;
      position: static;
    }

    .hero-slider__box-inner--product & {
      justify-content: space-between;
    }
  }

  &__price {
    &.price-info--inline {
      font-size: 50px;

      @media (min-width: $screen-tablet-landscape) {
        font-size: 64px;
      }
    }

    .price-info {
      &__old,
      &__current {
        width: 50%;
      }

      &__old-value {
        &::after {
          border-top: 3px solid $orangeColor;
        }
      }

      &__unit {
        font-weight: $fontWeightRegular !important;
        font-size: 11px;
      }
    }
  }

  &__flags.flags {
    position: absolute;
    top: -10px;
    right: -40px;
    bottom: auto;
    margin-top: 8px;

    @media (min-width: $screen-tablet-landscape) {
      top: -40px;
    }

    .flag {
      &--savings {
        transform: scale(.8);

        @media (min-width: $screen-tablet-portrait) {
          transform: scale(1);
        }

        @media (min-width: $screen-desktop) {
          transform: scale(1.2);
        }
      }

      &--award,
      &--vegan,
      &--bio {
        width: 50px;
        height: 50px;

        @media (min-width: $screen-tablet-portrait) {
          width: 56px;
          height: 56px;
        }

        &:first-child {
          @media (min-width: $screen-tablet-portrait) {
            right: 12px;
          }
        }
      }

      &--vegan,
      &--bio {
        font-size: $fontSizeXSmall;
      }
    }
  }

  & .carousel {
    &__page-info {
      display: none;
    }

    &__button {
      display: none;

      @media (min-width: $screen-tablet-portrait) {
        top: -255px;
        display: flex;
        height: 50px;
        width: 50px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
      }

      @media (min-width: $screen-tablet-landscape) {
        top: -275px;
      }

      @media (min-width: $screen-desktop) {
        top: -355px;
      }

      &--prev {
        left: 0;

        @media (min-width: $screen-desktop-xl) {
          left: 0;
        }
      }

      &--next {
        right: 0;

        @media (min-width: $screen-desktop-xl) {
          right: 0;
        }
      }
    }

    &__button-icon {
      position: relative;
      width: 40px;
      height: 40px;
      top: 0;
    }

    &__pagination-wrapper {
      @include container($maxWidthOuter);
      position: relative;
      margin: 8px auto 0;
    }

    &__pagination {
      display: flex;
      justify-content: center;
    }

    &__pagination-button {
      display: flex;
      cursor: pointer;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;

      .carousel__pagination-progress-bar {
        background: $carouselPaginationBackgroundColor;
        width: 10px;
        height: 10px;
        display: block;
        border-radius: 50%;
        transition: all 300ms;
      }

      &--active {
        .carousel__pagination-progress-bar {
          background: $carouselPaginationBackgroundColor;
          width: 16px;
          height: 16px;
        }
      }

      &:hover {
        .carousel__pagination-progress-bar {
          background: $carouselPaginationHoverBackground;
          width: 16px;
          height: 16px;
        }
      }

      &:active {
        .carousel__pagination-progress-bar {
          background: $carouselPaginationActiveBackground;
          width: 12px;
          height: 12px;
        }
      }
    }

    &__slide--active {
      width: 100%;
    }
  }

  &--hero {
    .carousel {
      &__button {
        @media (min-width: $screen-tablet-portrait) {
          display: none;
        }

        @media (min-width: $screen-desktop-xl) {
          display: block;
          top: -175px;
        }
      }

      &__pagination-wrapper {
        margin-top: 8px;

        @media (min-width: $screen-tablet-portrait) {
          margin: 16px auto;
        }

        @media (min-width: $screen-desktop) {
          margin: 24px auto 0;
        }
      }
    }
  }

  &--slider {
    #{$root}__text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px;

      @media (min-width: $screen-tablet-portrait) {
        padding: 40px;
      }
    }

    #{$root}__box-inner {
      padding: 0;
    }

    #{$root}__link-button {
      display: block;
      margin: 0 0 16px;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0 0 40px;
      }

      &--product-mobile {
        display: none;
      }
    }

    #{$root}__box-wrap {
      position: absolute;
      top: 0;
      bottom: auto;
      left: auto;
      right: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      margin: 0 auto;

      @media (min-width: $screen-tablet-portrait) {
        justify-content: center;
        max-width: 1500px;
        left: 0;
        right: 0;
      }
    }

    #{$root}__box {
      position: relative;
      width: calc(100% - 40px);
      height: auto;
      max-width: 400px;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      margin: 0;

      @media (min-width: $screen-desktop-sm) {
        max-width: 480px;
      }

      @media (min-width: $screen-desktop) {
        max-width: 580px;
      }
    }

    #{$root}__overline {
      position: absolute;
      width: 72px;
      height: 72px;
      top: -54px;
      right: 0;
      border-radius: 100%;
      font-size: 10px;
      font-weight: $fontWeightRegular;
      background: $redColor;
      color: $whiteColor;
      text-align: center;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @media (min-width: $screen-tablet-portrait) {
        font-size: 11px;
        width: 112px;
        height: 112px;
        top: -54px;
        right: -54px;
      }

      strong {
        display: block;
        font-size: 25px;
        font-weight: $fontWeightBold;
        padding: 0 0 2px;

        @media (min-width: $screen-tablet-portrait) {
          padding: 0 0 4px;
          font-size: 40px;
        }
      }
    }

    #{$root}__picture-wrap {
      height: auto;
      min-height: auto;
      max-height: none;

      @media (min-width: $screen-tablet-portrait) {
        height: auto;
      }
    }

    #{$root}__picture {
      display: flex;
      justify-content: center;
    }

    #{$root}__picture-image {
      position: relative;
      height: 100%;
      width: auto;
      z-index: 1;
      margin: auto;
      top: inherit;
      left: inherit;
      right: inherit;
      bottom: inherit;
      max-width: 767px;

      @media (min-width: $screen-tablet-portrait) {
        max-width: none;
      }
    }
  }

  &__slide-content {
    display: block;
    width: 100%;
    height: auto;
    text-decoration: none;

    @media (min-width: $screen-tablet-portrait) {
      &--position-left {
        #{$root}__box-wrap {
          align-items: flex-start;
        }
      }

      &--position-middle {
        #{$root}__box-wrap {
          align-items: center;
        }
      }

      &--position-right {
        #{$root}__box-wrap {
          align-items: flex-end;
        }
      }
    }
  }

  // PLACEHOLDER
  /* stylelint-disable-next-line */
  carousel {
    & > *:not(:first-child) {  // stylelint-disable-line
      display: none;
    }

    .hero-slider__picture {
      opacity: 1;
    }
  }

  &__navigation {
    &-placeholder {
      width: 100%;
      height: 38px;
    }
  }
}
